// Layout and grids
$content-width: 1170px;

// Fonts and sizes
$font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$primary-text-color: #000000;

html {
	font-size: 62.5%;
	font-family: $font;
	line-height: 1;
}

body {
	font-size: 2rem;
	background-color: $body-color;
}

.subPageBody {
	background-color: #fff;
}

h1 {
	font-size: 3rem;
}

h2 {
	font-size: 2rem;
}

h3 {
    font-size: 2rem;
}

// Better font rendering
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: $body-color;
}

a:link,
a:hover,
a:visited,
a:active {
	color: $accent-color;
	text-decoration: none;
}

// Shadows
$drop-shadow: 	drop-shadow(0px 5px 10px rgba(#000, 0.1)) 
				drop-shadow(0px 1px 1px rgba(#000, 0.2)
				);

// Various resets
*,
*::before,
*::after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	margin: 0;
	padding: 0;
}