$body-color: #000000;

$header-image: "assets/headerimage2.jpg";
$device-color: "assets/blue.png";

$accent-color: #eaa41d;

$header-title-color: #00dbde;
$app-title-color: #00dbde;
$app-price-color: #00dbde;
$app-description-color: #00dbde;

$feature-title-color: #00dbde;
$feature-text-color: #00dbde;
$footer-text-color: #00999b;

$header_color: #ffffff;
$header_transparency: 0.1;

$image-overlay-color: #363b3d;
$image-overlay-transparency: 0.8;

$feature-icons-foreground-color: #00dbde;
$feature-icons-background-color: #fc00ff;

$social-icons-foreground-color: #00dbde;
$social-icons-background-color: #fc00ff;

@import 
    "base",
    "layout",
    "github-markdown"